















































import { CacheService, Inject } from '@cds/common';
import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/components/common/layout.vue';
import { UserapplicationService } from '@/services/common/userapplication-service';
// import { any } from 'core-js/fn/promise';

@Component({
  components: {
    Layout,
  }
})

export default class App extends Vue {
    public radio:any = 3;
    public showPage:boolean = false; //防止页面闪现
    public showDialog:boolean = false;
    public listData:any = [];
    public listMap:any = {
      "contentlib":{
        img: require('@/assets/document.png'),
        color:'upcolor',
        name:'资料库'
      },
      "actalya_portal":{
        img: require('@/assets/act.png'),
        color:'upcolortwo',
        name:'ACTalya'
      },
      "rtw_portal":{
        img: require('@/assets/rtwwbf.png'),
        color:'upcolor',
        name:'微拜访RTW'
      },
      "monitor_portal":{
        img: require('@/assets/monitor_portal.png'),
        color:'upcolortwo',
        name:'WeCom监控'
      },
    };

    @Inject(UserapplicationService) private userapplicationService!: UserapplicationService;
    @Inject(CacheService) private cacheSvc!: CacheService;
    private userInfo: any = this.cacheSvc.get('user');

    public async created(): Promise<void> {
      //如果页面刷新的话window.performance.navigation.type的值为1，如果值不是1就证明页面首次加载
      if (window.performance.navigation.type === 1) {
        console.log('[App.vue]created: 页面被刷新')
        this.showDialog = false;
        this.showPage = true;
      } else {
      // 这里可以写调用什么方法
      //   console.log('页面首次被加载')
        this.showDialog = true;
        this.systemList()
      }
    }
    public async handleChange(url:any){
      // let url = this.listData.find(item => item.name == val).url
      // let url = this.listData[0].url;
      if(url.substring(0,4)=='http'){
         window.location.href =url;
      }else{
        let links =  `${location.origin}`+ url
        window.location.href =links;
      }

    }
    public weachatOk(){
      this.listData = [];
    }
    public async systemList(): Promise<void> {
       const res = await this.userapplicationService.systemList();
       this.showPage = true;
       if(res!=null){
          this.listData = res;
          this.listData.forEach((element:any) => {
            this.listMap[element.name].url = element.url;
          });
          console.log(this.listMap )
       }

    }
}
